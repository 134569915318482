.cardlist {
  width: 100%;
  flex: 1 1 100%;
  border: none;
  outline: none;
}

.cardlistTable {
  width: 100%;
  table-layout: auto;
}
.cardlistTableDark {
  background-color: #333;
}

tr:hover {
  background-color: #f5f5f5;
}

th,
td {
  border-bottom: 1px solid #ddd;
}
