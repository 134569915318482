.col-70 {
  width: 70%;
}

.col-30 {
  width: 30%;
}

.tab-menu {
  background-color: #333;
  width: 100%;
}

.tab-button {
  width: 50%;
  margin: 0;
  margin-bottom: 2px;
}

@media only screen and (max-width: 768px) {
  [class*='col-'] {
    width: 100%;
  }
}
