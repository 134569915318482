html,
body,
#root,
.App {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Libre Franklin', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button,
#button-like {
  border-radius: 1px;
  background-color: #333;
  border: none;
  color: white;
  padding: 0.5rem 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
button:hover:enabled {
  cursor: pointer;
  background-color: #ddd;
  color: black;
}
button:disabled {
  background-color: silver;
}
input,
textarea {
  font-family: 'Libre Franklin', sans-serif;
  border: 1px lightgray solid;
  border-radius: 1px;
  font-size: 1em;
  padding: 0.5em;
}
