.cardmodal {
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/* Modal Content/Box */
.cardmodal-content {
  position: absolute;
  flex: 1 1 50%;
  margin-top: 5%;
  overflow: auto;
  width: 50%;
}

@media only screen and (max-width: 768px) {
  .cardmodal-content {
    flex: 1 1 100%;
    width: 100%;
  }
  .cardmodal {
  }
}

/* The Close Button */
.close {
  position: sticky;
  top: 25;
  right: 0;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
